import { useRef } from "react";

import Home from "./pages/Home";
import About from "./pages/About";
import Skills from "./pages/Skills";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";

import Footer from "./components/Footer";

import DownloadResume from "./utils/DownloadResume";

function App() {
	const aboutRef = useRef(null);
	const skillsRef = useRef(null);
	const projectsRef = useRef(null);
	const contactRef = useRef(null);

	const scrollToSection = (elementRef) => {
		window.scrollTo({
			top: elementRef.current.offsetTop,
			behavior: "smooth",
		});
	};

	// const scrollToTop = () => {
	// 	window.scrollTo({
	// 		top: 0,
	// 		behavior: "smooth",
	// 	});
	// };

	// const [showScrollButton, setShowScrollButton] = useState(false);

	// const handleScroll = () => {
	// 	const aboutSectionTop = aboutRef.current.offsetTop;
	
	// 	if (window.scrollY > aboutSectionTop - window.innerHeight) {
	// 		setShowScrollButton(true);
	// 	} else {
	// 		setShowScrollButton(false);
	// 	}
	// };
	
	// useEffect(() => {
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 	};
	// }, []);

	return (
		<div className="App">
			<div className="nav-container">
				<nav>
					{/* <span onClick={() => scrollToTop()} className="nav-link">Home</span> */}
					<span onClick={() => scrollToSection(aboutRef)} className="nav-link">About</span>
					<span onClick={() => scrollToSection(skillsRef)} className="nav-link">Skills</span>
					<span onClick={() => scrollToSection(projectsRef)} className="nav-link">Projects</span>
					<span onClick={() => scrollToSection(contactRef)} className="nav-link">Contact</span>
						<div className="nav-resume">
					<span onClick={() => DownloadResume()} className="nav-link">Resume</span>
					</div>
				</nav>
			</div>
			<Home />
			<About ref={aboutRef} />
			<Skills ref={skillsRef} />
			<Projects ref={projectsRef} />
			<Contact ref={contactRef} />
			<Footer />
			{/* {showScrollButton && (
				<div className="scroll-button" onClick={scrollToTop}>
					<i className="material-icons swipe-arrow">keyboard_double_arrow_up</i>
				</div>
			)} */}
		</div>
	);
}

export default App;
