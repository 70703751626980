import * as LanguageIcons from "../icons/LanguageIcons"
import * as FnLIcons from "../icons/FnLIcons"
import * as TnTIcons from "../icons/TnTIcons"

export const LanguageArray = () => [
  <LanguageIcons.Python />,
  <LanguageIcons.C />,
  <LanguageIcons.Cplusplus />,
  <LanguageIcons.Java />,
  <LanguageIcons.HTML />,
  <LanguageIcons.CSS />,
  <LanguageIcons.JavaScript />,
  <LanguageIcons.Bash />,
  <LanguageIcons.R />,
  <LanguageIcons.MATLAB />,
];

export const FnLArray = () => [
  <FnLIcons.React />,
  <FnLIcons.Express />,
  <FnLIcons.Pytest />,
  <FnLIcons.Numpy />,
]

export const TnTArray = () => [
  <TnTIcons.GitHub />,
  <TnTIcons.Terraform />,
  <TnTIcons.AWS />,
  <TnTIcons.GoogleCloud />,
  <TnTIcons.VSCode />,
  <TnTIcons.Jupyter />,
  <TnTIcons.Vim />,
  <TnTIcons.MySQL />,
  <TnTIcons.MongoDB />,
  <TnTIcons.Node />
]
