import React from "react";
import IconBlock from "../components/IconBlock";

import DownloadResume from "../utils/DownloadResume";

import { LightbulbIcon, PaletteIcon, GearIcon } from "../icons/AboutIcons";

const About = React.forwardRef((props, ref) => {
	return (
		<div ref={ref} className="small-container">
			<div className="small-box">
				<div className="heading">About Me</div>
				<div className="body about-container">
					<div className="description-area">
						<div className="description-text">
							I am a Software Engineering student with an interest in using
							software solutions to solve real-world problems. With a passion
							for exploring new technologies, developing innovative systems, and
							building meaningful connections, I am dedicated to contributing my
							skills and knowledge to the ever-evolving tech landscape. Please
							feel free to reach out!
						</div>
						<div className="description-resume">
							<button className="download-btn" onClick={() => DownloadResume()}>
								<span>Download Resume</span>
								<i className="material-icons">download</i>
							</button>
						</div>
					</div>
					<div className="icons-container">
						<IconBlock
							icon={<LightbulbIcon />}
							label={"Innovative"}
							link={""}
						/>
						<IconBlock icon={<PaletteIcon />} label={"Creative"} link={""} />
						<IconBlock icon={<GearIcon />} label={"Productive"} link={""} />
					</div>
				</div>
			</div>
		</div>
	);
});

export default About;
