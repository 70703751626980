const DownloadResume = () => {
  const link = document.createElement('a');
  link.href = "Tony_s_Resume.pdf";
  link.download = "Tony_s_Resume.pdf";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default DownloadResume
