import React, { useState, useEffect } from "react";
import Card from "../components/Card";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

import SwiperNavButtons from "../components/SwiperNavButtons";

import {
	Keyboard,
	Scrollbar,
	Navigation,
	Pagination,
	Mousewheel,
} from "swiper/modules";

import { projectData } from "../content/projectsContent";

const Projects = React.forwardRef((props, ref) => {
  const [slidesPerView, setSlidesPerView] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesPerView(1);
      } 
      else if (window.innerWidth < 1024) {
        setSlidesPerView(2);
      } 
      else {
        setSlidesPerView(3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={ref} className="container">
      <div className="box">
        <div className="heading">Projects</div>
        <div className="body projects-container">
          <Swiper
            slidesPerView={slidesPerView}
            slidesPerGroupSkip={1}
            // centeredSlides={true}
            grabCursor={true}
            keyboard={{ enabled: true }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
              clickable: true,
            }}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              dynamicBullets: true,
            }}
            loop={true}
            modules={[Keyboard, Scrollbar, Navigation, Pagination, Mousewheel]}
          >
            {projectData().map((project, index) => (
              <SwiperSlide key={index}>
                <Card {...project} />
              </SwiperSlide>
            ))}
            <div className="swiper-nav-btns">
              <SwiperNavButtons />
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
});

export default Projects;