import React from "react";

const Home = () => {
	const handleScroll = () => {
		const parallax = document.querySelectorAll(".parallax-layer");

		window.addEventListener("scroll", () => {
			let scrollPosition = window.scrollY;

			parallax.forEach((element) => {
				const speed = parseFloat(element.dataset.speed);
				element.style.transform = `translateY(${scrollPosition * speed}px)`;
			});
		});
	};

	React.useEffect(() => {
		handleScroll();
	}, []);

	return (
		<div className="parallax-container">
			<div className="parallax-layer" data-speed="1">
				<div className="animation-layer" id="home-content">
					<div className="home-top">
					</div>
					<div className="home-bot">
						<div className="title-name">Tony Tran</div>
						<div className="title-position">Software Engineering Student</div>
					</div>
				</div>
			</div>
			<div className="parallax-layer" data-speed="0.5">
				<div className="animation-layer" id="wave1"></div>
			</div>
			<div className="parallax-layer" data-speed="0.25">
				<div className="animation-layer" id="wave2"></div>
			</div>
			<div className="parallax-layer" data-speed="0">
				<div className="animation-layer" id="wave3"></div>
			</div>
		</div>
	);
};

export default Home;
