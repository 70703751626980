import React, { useState, useEffect } from 'react'

const Footer = () => {
  const [copyright, setCopyright] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCopyright("&copy; 2024 Tony Tran");
      } 
      else if (window.innerWidth < 1024) {
        setCopyright("&copy; 2024 Tony Tran. All Rights Reserved.");
      } 
      else {
        setCopyright("Copyright &copy; 2024 Tony Tran. All Rights Reserved.");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

  return (
    <footer>
    <div className="copyright" dangerouslySetInnerHTML={{ __html: copyright }} />
    <div className="scroll-container">
      <div className="scroll-btn" onClick={scrollToTop}>
        <i className="material-icons">keyboard_double_arrow_up</i>
      </div>
    </div>
  </footer>
  )
}

export default Footer