export const projectData = () => [
	// {
	//   icon: <i className="material-icons logo">home</i>,
	//   name: "Project's Name",
	//   type: "Project's type",
	//   techs: ["tech1", "tech2", "tech2"],
	//   pageLink: "/",
	//   githubLink: "/",
	// },
	{
		icon: <i className="material-icons logo">search</i>,
		name: "Wordle Clone",
		type: "Web Development",
		techs: ["HTML", "CSS", "JavaScript"],
		pageLink: "https://tonaeus-wordle-clone.netlify.app/",
		githubLink: "https://github.com/Tonaeus/Wordle",
	},
	{
		icon: <i className="material-icons logo">edit</i>,
		name: "Notion Clone",
		type: "Web Development",
		techs: ["HTML", "CSS", "React"],
		pageLink: "https://tonaeus-notion-clone.netlify.app",
		githubLink: "https://github.com/Tonaeus/Lotion-1",
	},
	{
		icon: <i className="material-icons logo">calendar_today</i>,
		name: "Clinic Schedule",
		type: "Object-Oriented Development",
		techs: ["Java", "OOP", "UML", "JUnit", "MySQL"],
		pageLink: "",
		githubLink: "https://github.com/chantaeh/vet_records_ensf380_project",
	},
	{
		icon: <i className="material-icons logo">bookmark</i>,
		name: "Data Structure Library",
		type: "Library Development",
		techs: ["Python", "OOP", "Pytest"],
		pageLink: "https://pypi.org/project/ensf338grp26prj/",
		githubLink: "",
	},
	{
		icon: <i className="material-icons logo">analytics</i>,
		name: "Global Population",
		type: "Data Visualization",
		techs: ["Python", "Numpy", "Matplotlib"],
		pageLink: "",
		githubLink: "https://github.com/Tonaeus/Endg233-Final-Project",
	},
];
