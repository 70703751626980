import React from "react";
import { useSwiper } from "swiper/react";

const SwiperNavButtons = () => {
	const swiper = useSwiper();

	return (
		<>
			<div className="prev-btn-container">
				<button className="swipe-btn" onClick={() => swiper.slidePrev()}>
					<i className="material-icons swipe-arrow">navigate_before</i>
				</button>
			</div>
			<div className="pagination-btn">
				<div className="swiper-pagination"></div>
			</div>
			<div className="next-btn-container">
				<button className="swipe-btn" onClick={() => swiper.slideNext()}>
					<i className="material-icons swipe-arrow">navigate_next</i>
				</button>
			</div>
		</>
	);
};

export default SwiperNavButtons;
