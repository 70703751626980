import { Visit, GitHub } from "../icons/ProjectsIcons";

const Card = (props) => {
	return (
		<div className="card">
			<div className="card-top">
				<div className="logo-container">{props.icon}</div>
			</div>
			<div className="card-bot">
				<div className="name">{props.name}</div>
				<div className="type">{props.type}</div>
				<div className="tech-container">
					{props.techs &&
						props.techs.map((tech, index) => (
							<button key={index} className="tech-btn">
								{tech}
							</button>
						))}
				</div>
				<div className="project-btn-container">
				{props.pageLink ? 
					<a href={props.pageLink} target="_blank" rel="noopener noreferrer" className="project-btn">
						<Visit />
					</a> : null}
					{props.githubLink ? 
					<a href={props.githubLink} target="_blank" rel="noopener noreferrer" className="project-btn">
						<GitHub />
					</a> : null}
				</div>
			</div>
		</div>
	);
};

export default Card;
