import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import IconBlock from "../components/IconBlock";

import { LocationIcon, LinkedInIcon, GitHubIcon } from "../icons/ContactIcons"

const Contact = React.forwardRef((props, ref) => {
	const formRef = useRef();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const sendEmail = (e) => {
		e.preventDefault();

		if (isSubmitting) {
			return;
		}
	
		setIsSubmitting(true);

		emailjs.sendForm("service_zc6b1bc", "template_ozlvqsd", formRef.current, "7kK9bYRQi7UgsfmLo")
		  .then((result) => {
		      // console.log(result.text);
					formRef.current.reset();
					// setTimeout(() => {
					// 	window.alert("Message sent successfully!");
					// }, 100);
					window.alert("Message sent successfully!");
		  }, (error) => {
		      // console.log(error.text);
		  })
			.finally(() => {
        setIsSubmitting(false);
      });
	};

	return (
		<div ref={ref} className="small-container">
			<div className="small-box">
				<div className="heading">Contact Me</div>
				<div className="body contact-container">
					<div className="contact-area">
						<IconBlock icon={<LocationIcon />} label={"Location"} link={"https://www.google.com/maps/place/Calgary"}/>
						{/* <ContactBlock icon={<PhoneIcon />} label={""}/>
						<ContactBlock icon={<MailIcon />} label={""} /> */}
						<IconBlock icon={<LinkedInIcon />} label={"LinkedIn"} link={"https://www.linkedin.com/in/tonaeus"}/>
						<IconBlock icon={<GitHubIcon />} label={"GitHub"} link={"https://github.com/tonaeus"}/>
					</div>
					<form className="contact-form" ref={formRef} onSubmit={sendEmail}>
						<div className="name-email-container">
							<div className="name-container">
								{/* <label>Name</label> */}
								<input className="name-input" type="text" name="user_name" placeholder="Name" required readOnly={isSubmitting}/>
							</div>
							<div className="email-container">
								{/* <label>Email</label> */}
								<input className="email-input" type="email" name="user_email" placeholder="Email" required readOnly={isSubmitting}/>
							</div>
						</div>
						<div className="message-container">
							{/* <label>Message</label> */}
							<textarea name="message" placeholder="Message" required readOnly={isSubmitting}/>
						</div>
						<input className={`submit-btn ${isSubmitting ? 'submitting' : ''}`} type="submit" value={isSubmitting ? "Sending" : "Send"} disabled={isSubmitting}/>
					</form>
				</div>
			</div>
		</div>
	);
});

export default Contact;
