import React, { useState, useEffect } from "react";

import Grid from "../components/Grid";

import { LanguageArray, FnLArray, TnTArray } from "../content/skillsContent"

const Skills = React.forwardRef((props, ref) => {
	const [column, setColumn] = useState();

  useEffect(() => {
		const handleResize = () => {
      if (window.innerWidth < 480) {
        setColumn(4)
      }
      else if (window.innerWidth < 768) {
        setColumn(5)
      } 
      else if (window.innerWidth < 1024) {
        setColumn(7)
      } 
      else {
        setColumn(3)
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

	return (
    <div ref={ref} className="container">
      <div className="box">
        <div className="heading">Skills</div>
        <div className="body skills-container">
          {column > 3 ? (
						<div className="technical-area">
							<Grid array={[...LanguageArray(), ...FnLArray(), ...TnTArray()]} column={column}/>
						</div>
          ) : (
            <>
              <div className="languages">
                <div className="skill-area">Programming Languages</div>
                <Grid array={LanguageArray()} column={column}/>
              </div>
              <div className="technologies">
                <div className="skill-area">Frameworks & Libraries</div>
                <Grid array={FnLArray()} column={column}/>
              </div>
              <div className="tools">
                <div className="skill-area">Tools & Technologies</div>
                <Grid array={TnTArray()}column={column}/>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default Skills;