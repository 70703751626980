import React from "react";

const Grid = (props) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {props.array.map((icon, index) => (
        <div
          key={index}
          className="grid-item"
          style={{
            width: `calc(${100 / props.column}% - 10px)`,
          }}
        >
          {icon}
        </div>
      ))}
    </div>
  );
};

export default Grid;
